<template>
  <div>

    <div :id="chartName" class="chart" :style="{width: '100%', height: '400px','min-width':'600px','margin-top':'8px'}"></div>
  </div>
</template>
   
   <script>
import * as echarts from 'echarts';
export default {
  name: 'xwPassengerFlow',
  props: {
    chartName: {
      type: String,
      default: 'myCharta',
    },
    dataList: {
      type: Array,
      default: () => {},
    },
    showChart: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {

  },
  watch: {
    list: {
      handler(newVal) {
        this.loadLine()
      },
      deep: true
    },
    showChart: {
      handler(newVal) {
        console.log(this.chartName, this.dataList);
        this.loadLine()
      },
      deep: true
    },
    dataList: {
      handler(newVal) {
        this.loadLine()
      },
      deep: true
    }
  },

  methods: {
    formatMoney(money, t) {
      let result = money;
      if(money) {
        var parts = money.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if(parts.length > 1) {
          result = parts.join(".")
        } else {
          result = parts[0]
        }
      }
      return result;
    },
    loadLine() {
      if( this.dataList && this.dataList.length > 0) {
        this.list = this.dataList;
      }
      this.myChartOne = echarts.init(document.getElementById(this.chartName))
      this.myChartOne.showLoading();
      let a = this.list
      let b = []
      let c = []
      let d = []
      let amount_list = []
      let sum_list = []
      a.forEach(e => {
        b.push(e.date)
        c.push(e.saleAmount)
        d.push(e.saleSum)
        let amount = this.formatMoney(e.saleAmount, 'a')
        amount_list.push(amount)
        let sum = this.formatMoney(e.saleSum, 'b')
        sum_list.push(sum)
      })
      const option = {
        tooltip: {
          trigger: 'axis',
          showContent: true,
          backgroundColor: 'transparent',
          padding: 0,
          // 提示框浮层内容格式器: 自定义提示框样式
          formatter: function(params) { // params 是 formatter 需要的数据集
            let content = "";
            params.forEach((item) => {
              // 提示框的内容样式及数据
              let first = ''
              let end = ''
              let part = ''
              if(item.seriesName == "销售额指数") {
                first = '<div style="width: 100%; background: rgba(255,255,255,0.9);padding: 10px 8px;border-radius:4px">'
                end = `<span style="color: #424864;">${amount_list[item.dataIndex]}</span>
        </div>`
              } else {
                first = '<div style="width: 100%;  background: rgba(255,255,255,0.9);padding: 10px 8px;margin-top:4px;border-radius:4px">'
                end = `<span style="color: #424864;">${sum_list[item.dataIndex]}</span>
        </div>`
              }
              part = `
          <span style="display: inline-block; margin-right: 10px; border-radius: 50%; width: 9px; height: 9px;background-color: ${item.color};"></span>
          <span style="color: #424864;">${item.seriesName}:</span>`
              content += first + part + end;
            });
            // 提示框外框样式及内容数据
            const htmlContent = `<div style="width: 100%; height: 100%;border-radius: 6px 6px 6px 6px;background: linear-gradient( 321deg, #FDFEFF 0%, #F4F7FC 100%);padding:7px 8px 8px 7px">
            <div style="font-size:11px;margin-left:6px;height:19px;color:#1D2129;font-weight:bold;margin-bottom:4px">   
        ${params[0].value[0]}   
         </div>
          ${content}
          `;

            return htmlContent;
          },
        },
        dataset: {
          source: [
            ['product', ...b],
            ['销售额指数', ...c],
            ['销售量指数', ...d],

          ]
        },
        xAxis: { type: 'category' },
        yAxis: {
          gridIndex: 0,
          axisLabel: {
            margin: 20 // 增加标签边距
          },
        },
        grid: { containLabel: true },
        color: ['#4975E9', '#E34D59'],
        legend: {       //对图形的解释部分
          icon: 'circle',
          itemWidth: 20,
          itemHeight: 10,
          itemGap: 10,
          top: 32
        },
        series: [
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            symbol: 'none',
            emphasis: { focus: 'series' },
            lineStyle: {
              color: '#5470C6',
              width: 1
            },
          },
          {
            type: 'line',
            smooth: true,
            symbol: 'none',
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            lineStyle: {
              color: '#E34D59',
              width: 1
            },
          }
        ]
      };



      this.$nextTick(() => {
        this.myChartOne.hideLoading();
        this.myChartOne.setOption(option)
         this.myChartOne.resize();
      })
    }
  }
}
   </script>

