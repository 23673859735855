<template>
  <div class="same-pic">
    <el-dialog title="" :visible.sync="showWin" @close='colseWin' destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="same-pic-head">
        <div class="same-pic-head-left">

          <el-upload :show-file-list="false" action="#" :http-request="startUpload" list-type="picture-card" :on-change="changePic" :limit="1" :before-upload="beforeAvatarUpload" :auto-upload='true' :disabled='imgUrl ? true :false' ref="myUpload">

            <div v-if="imgUrl" style="position: relative;">
              <el-image :src="require('../assets/images/guanbi.png')" fit="fill" lazy @click.stop="colseWin(true)" style="position:absolute;z-index: 2;right: 10px;width:15px;height:15px;top:10px;"></el-image>
              <el-image :src="imgUrl" fit="cover" lazy style="width:146px;height:146px;border-radius:20px"></el-image>
            </div>
            <div v-else>
              <el-image :src="require('../assets/imgs/upload_before.png')" fit="fill" lazy></el-image>
            </div>
          </el-upload>

        </div>
        <div class="same-pic-head-right font-24">
          {{imgUrl ? '相似图片' : '' }}
          <div class="same-pic-head-right-desc font-14" v-if="imgUrl">
            在{{ activeName | decode('','All','1','Temu','2','Shein') }}下 存在{{total_num}}个同款产品,
            <span v-if="total_num <= 5">
              建议申报价格N +
              <span>
                {{total_num | decode( 0,'10~13',1,'8~10',2,'7~9',3,'6~8',4,'5~7',5,'3~5','' )}}元
              </span>
              （N代表鞋子成本价）
            </span>
            <span v-else>超过5个同款商品不建议做此款</span>
            <div>
              此建议仅供参考，具体以实际平台审核为准
            </div>
          </div>
        </div>

      </div>
      <div class="same-pic-from">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="item in from_list" :key="item.name"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="same-pic-type">
        <div class="same-pic-type-list">
          <div class="same-pic-type-list-item" v-for="item in type_list" :key="item.value" @click="changeType(item)">
            <el-button type='text' :class=" active_type == item.value ? 'same-pic-type-list-item-desc same-pic-type-list-item-desc-choose font-16' : 'same-pic-type-list-item-desc font-16'">{{item.label}}</el-button>
            <div class="same-pic-type-list-item-icon" v-if="item.value != 0 ">
              <i :class=" active_sort % 3 == 1 && active_type == item.value ? 'el-icon-caret-top font-10 same-pic-type-list-item-icon-choose' :   'el-icon-caret-top font-10' "></i>
              <i :class=" active_sort % 3 == 2 && active_type == item.value ? 'el-icon-caret-bottom font-10 same-pic-type-list-item-icon-choose' :   'el-icon-caret-bottom font-10' "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="same-pic-content" v-loading.fullscreen.lock="isLoading">
        <div class="same-pic-content-list" @scroll="handleScroll" ref="inner" v-if="goods_list && goods_list.length > 0">
          <div class="same-pic-content-list-item" v-for="item in goods_list" :key="item.id" @click="spxq(item.id)">
            <el-popover placement="top" trigger="hover">
              <div>
                <img :src="item.imageUrl || require('../assets/imgs/empty.png')  " alt="" style="height:250px;width:250px;">
              </div>
              <div class="same-pic-content-list-item-pic" slot="reference">
                <img :src="item.imageUrl || require('../assets/imgs/empty.png')  " alt="" style="height:150px;width:150px;border-radius:20px">
              </div>
            </el-popover>

            <div class="same-pic-content-list-item-desc">
              {{item.name || ''}}
            </div>
            <div class="same-pic-content-list-item-price">
              {{item.currencySymbol}}{{item.price || '0.00'}}
            </div>
          </div>
        </div>
        <div class="same-pic-content-empty font-24" v-else>
          暂无数据
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { listSimilarProduct, listSimilarOnLineProduct } from "../api/api";
export default {
  name: 'same-pic',
  props: {
    showSearch: {
      default: false,
      type: Boolean
    },
    pic_url: {
      default: '',
      type: String
    },
  },
  data() {
    return {
      count: 0,
      imgUrl: '',
      activeName: '0',
      last_active_name: '1',
      showWin: false,
      same_type: 0.8,
      from_list: [
        // {
        //   label: '1688插件',
        //   name: '7'
        // },
        // {
        //   label: '1688寻源通',
        //   name: '8'
        // },
        // {
        //   label: '1688分销',
        //   name: '3'
        // },
        // {
        //   label: '1688跨界',
        //   name: '4'
        // },
        // {
        //   label: '拼多多',
        //   name: '5'
        // },
        // {
        //   label: '淘宝',
        //   name: '6'
        // },
        {
          label: 'All',
          name: '0',
        },
        {
          label: 'Temu',
          name: '1'
        },
        {
          label: 'Shein',
          name: '2'
        },
      ],
      type_list: [
        {
          label: '综合',
          value: '0',
        },
        {
          label: '销量',
          value: '1',
        },
        {
          label: '价格',
          value: '3',
        }
      ],
      active_type: '0',
      active_sort: 1,
      goods_list: [],
      page: 1,
      isLoading: false,//节流
      total_num: 0,
      scroll_postion: 0,
    }
  },
  methods: {
    startUpload(file, fileList) {
      console.log(11, file, fileList);
    },
    spxq(e) {
      const routeData = this.$router.resolve({ path: '/productid', query: { id: e } }).href;
      console.log('routeData', routeData)
      //   // 打开新窗口
      window.open(routeData, '_blank');
    },
    // 滚动
    handleScroll(e) {
      return;
      if(this.isLoading) return
      // 当滚出去的内容高度加上窗口也就是外部容器的高度大于等于内容高度时发送请求
      const scroll = this.$refs.inner;
      if(scroll.scrollTop < this.scroll_postion) return;
      this.scroll_postion = scroll.scrollTop + 30;
      //加多少就是距离底部多少就触发加载
      if(parseInt(scroll.scrollTop + 30) + scroll.clientHeight >= scroll.scrollHeight) {
        // this.getList();
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$message.info('已经到底了');
        }, 500);
        console.log('到底了~~');
      }
    },
    //获取相似照片信息
    async getList(reset = false) {
      if(!this.imgUrl) {
        return;
      }
      this.isLoading = true;
      if(reset) {
        this.page = 1;
        this.goods_list = [];
        if(this.$refs && this.$refs.inner) {
          this.$refs.inner.scrollTop = 0;
        }
      }
      let param = {};
      param['source'] = this.activeName == '0' ? '' : this.activeName;
      param['page'] = this.page;
      param['pageSize'] = 20;
      param['sortField'] = this.active_type;
      param['sortType'] = this.active_sort % 3 == 1 ? 'asc' : 'desc';
      param['degree'] = this.same_type && this.same_type > 0 ? this.same_type : 0.8;
      let image_type = this.imgUrl.slice(0, 4) == 'http' ? true : false;
      if(image_type) {
        //根据url查相似
        param['imageUrl'] = this.imgUrl;
        const { data: res } = await listSimilarOnLineProduct(param);
        const { totalNum, pageData } = res.data;
        this.total_num = totalNum;
        const { records } = pageData;
        this.isLoading = false;
        if(records && records.length > 0) {
          if(reset) {
            this.goods_list = [].concat(records)
          } else {
            this.goods_list = this.goods_list.concat(records)
          }
        } else {
          if(this.goods_list && this.goods_list.length > 0) {
            this.$message.info('已经到底了')
          }
        }
      } else {
        //根据base64 查相似
        param['imageEncodeStr'] = this.imgUrl;
        const { data: res } = await listSimilarProduct(param);
        const { totalNum, pageData } = res.data;
        this.total_num = totalNum;
        const { records } = pageData;
        this.isLoading = false;
        if(records && records.length > 0) {
          if(reset) {
            this.goods_list = [].concat(records)
          } else {
            this.goods_list = this.goods_list.concat(records)
          }
        } else {
          if(this.goods_list && this.goods_list.length > 0) {
            this.$message.info('已经到底了')
          }
        }
      }
      this.page++;
    },
    //关闭弹窗
    colseWin(flag = false) {
      this.imgUrl = '';
      this.page = 1;
      this.goods_list = [];
      this.active_type = '0';
      this.active_sort = 1;
      this.activeName = '';
      if(flag) {
        this.$refs.myUpload.clearFiles();
        this.getList(true);
      } else {
        this.$emit('close');
      }
    },
    //切换类型
    changeType(data) {
      if(this.active_type == data.value) {
        if(this.active_type == 0) {
          return;
        }
        this.active_sort += 1;
      } else {
        this.active_type = data.value;
        this.active_sort = 0;
      }
      this.getList(true);
    },
    //上传图片
    changePic(file, fileList) {
      console.log('changePic', file, fileList);
      if(file.status !== 'ready') return;
      const { url, size } = file;
      const isLt10M = size / 1024 / 1024 < 10;
      if(!isLt10M) {
        this.$message.error('上传图片大小不能超过10MB!');
      }
      if(url) {
        const image = new Image() // 新建一个img标签（还没嵌入DOM节点)
        //blob:http://localhost:8080/b878edea-e649-4f8d-b723-c3f25454141b
        image.src = url;
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height);
          let imgUrl = canvas.toDataURL() //图片的base64地址
          this.imgUrl = imgUrl;
          this.hideUpload = true;
          this.$nextTick(() => {
            this.getList();
          })
        }
      }
    },
    //切换来源
    handleClick() {
      if(this.activeName != this.last_active_name) {
        this.last_active_name = this.activeName;
        this.getList(true);
      }
    },
    beforeAvatarUpload(file) {

      const { size } = file;
      const isLt10M = size / 1024 / 1024 < 10;
      if(!isLt10M) {
        this.$message.error('上传图片大小不能超过10MB!');
      }
      return isLt10M;
    }
  },
  watch: {
    pic_url(val) {
      this.imgUrl = val;
    },
    showSearch(val) {
      this.showWin = val;
      this.imgUrl = this.pic_url;
      if(val) {
        this.getList();
      }
    }
  }
}
</script>
<style lang="less" scoped>
.same-pic {
  /deep/ .is-active {
    background-color: none !important;
  }
  /deep/.el-dialog__body {
    padding-top: 0;
    padding-bottom: 20px;
  }
  &-head {
    display: flex;
    align-items: center;
    &-left {
      margin-right: 48px;
      /deep/ .el-upload--picture-card {
        border: none;
      }
    }
    &-right {
      font-weight: bold;
      color: #333;
      &-desc {
        font-weight: normal;
        color: #b8b8b8;
        margin-top: 23px;
      }
    }
  }
  &-from {
    margin-top: 16px;
    /deep/ .el-tabs__item.is-active {
      color: #4975e9;
      font-size: 16px;
    }
    /deep/.el-tabs__item {
      font-size: 16px;
    }
    /deep/.el-tabs__active-bar {
      background-color: #4975e9;
    }
  }
  &-type {
    &-list {
      display: flex;
      &-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        &-active {
          color: #4975e9;
        }
        margin-right: 15px;
        &-desc {
          color: #333;
          margin-right: 4px;
          &-choose {
            color: #4975e9;
          }
        }
        &-icon {
          display: grid;
          color: #d9d9d9;
          &-choose {
            color: #4975e9;
          }
        }
      }
    }
  }
  &-content {
    // display: flex;
    height: 400px;
    &-empty {
      height: 100%;
      display: flex;

      justify-content: center;
      text-align: center;
      align-items: center;
      color: #b8b8b8;
    }
    &-list {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      &-item {
        min-width: 182px;
        width: calc(100% / 5 - 32px);
        height: 270px;
        border-radius: 20px;
        border: 1px solid #d9d9d9;
        padding: 16px;
        margin-right: 30px;
        margin-top: 16px;
        &-desc {
          margin-top: 8px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          /* 设置文本超出部分省略 */
          text-overflow: ellipsis;
        }
        &-price {
          margin-top: 4px;
          color: #e34d59;
        }
      }
    }
  }
}
</style>