<template>
  <div class="diadin product" v-loading='loading'>
    <div class="product-head">
      <div class="product-head-img">
        <img style="width: 200px;height: 200px;border-radius: 10px;" :src="(forme.imageUrl || require('../../assets/imgs/empty.png')) | image(200,200)" alt="">
      </div>
      <div class="product-head-content font-12">
        <div>
          <div class="product-head-content-title">
            <div class="product-head-content-title-name">
              {{forme.name || ''}}
              <span @click="copyTex(forme.name)" style="line-height: 31px;border:1px solid #33333365; width: 103px;height: 31px;background-color: #43434313;color: #333333;margin-right: 20px;border-radius: 5px;cursor: pointer;"> <i class="el-icon-document-copy"></i> 复制</span>
            </div>
            <div class="product-head-content-title-name product-head-content-title-name-en" v-if="forme.productEnName">
              {{forme.productEnName || ''}}
              <span @click="copyTex(forme.productEnName)" style="line-height: 31px;border:1px solid #33333365; width: 103px;height: 31px;background-color: #43434313;color: #333333;margin-right: 20px;border-radius: 5px;cursor: pointer;"> <i class="el-icon-document-copy"></i> 复制</span>
            </div>
            <div class="product-head-content-title-type">
              <el-tag>{{forme.firstCategory+','+forme.secondCategory}}</el-tag>
            </div>
          </div>
          <div class="font-14 product-head-content-btn">
            <div @click="tzym(forme.detailLink)" style="width: 103px;height: 31px;background-color: #4975E9;color: #FFFFFF;margin-right: 20px;border-radius: 5px;cursor: pointer;"><i class="el-icon-link"></i>商品链接</div>
            <div @click="tzym(forme.shopLink)" style="width: 103px;height: 31px;background-color: #a4b9f42f;color: #4975E9;border-radius: 5px;cursor: pointer;"><i class="el-icon-link"></i>店铺链接</div>
            <el-divider direction="vertical" class="diadin_divider" style=""></el-divider>
            <div @click="findSame" style="width: 103px;height: 31px;background-color: rgba(51, 51, 51, 0.08);color: #333333;border-radius: 5px;cursor: pointer;margin-right: 20px;"><i class="el-icon-search"></i>图搜同款</div>
            <div v-if="this.user && this.user.id" @click="toCollectProduct(forme.isCollect ? 1 : 0)" style="width: 103px;height: 31px;background-color:#008000;color: #fff;border-radius: 5px;cursor: pointer;">{{ !forme.isCollect ? '收藏' : '取消收藏'}}</div>
          </div>
        </div>

      </div>
    </div>
    <!--  -->
    <div class="font-14 product-info">
      <el-row>
        <el-col :span="6">
          <div class="product-info-title">上架时间</div>
          <div class="product-info-desc">{{forme.onlineTime || '/' }}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">开店时间</div>
          <div class="product-info-desc">{{forme.shopTime || '/'}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">库存</div>
          <div class="product-info-desc">{{forme.quantity || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">价格（$）</div>
          <div class="product-info-desc">{{forme.price || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">总销量</div>
          <div class="product-info-desc">{{forme.sales || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">日销量</div>
          <div class="product-info-desc">
            {{forme.dailySold  || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.dailySoldIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.dailySoldIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">周销量</div>
          <div class="product-info-desc">
            {{forme.weekSold || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.weekSoldIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.weekSoldIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">月销量</div>
          <div class="product-info-desc">
            {{forme.monthSold || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.monthSoldIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.monthSoldIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">总销售额（$）</div>
          <div class="product-info-desc">{{forme.salesAmount || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">日销售额（$）</div>
          <div class="product-info-desc">
            {{forme.dailySalesAmount  || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.dailySalesAmountIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.dailySalesAmountIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">周销售额（$）</div>
          <div class="product-info-desc">
            {{forme.weekSalesAmount || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.weekSalesAmountIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.weekSalesAmountIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">月销售额（$）</div>
          <div class="product-info-desc">
            {{forme.monthSalesAmount || 0 | formatMoney}}
            <span>
              <i class="el-icon-caret-top" style="color:red" v-if="forme.monthSalesAmountIncrease > 0"></i>
              <i class="el-icon-caret-bottom" style="color:green" v-else></i>
            </span>
            {{ forme.monthSalesAmountIncrease || '0.00' | formatPercent}}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">评分</div>
          <div class="product-info-desc">{{forme.score || 0}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">评论数</div>
          <div class="product-info-desc">{{forme.commentsCount || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">店铺销量</div>
          <div class="product-info-desc">{{forme.shopSold || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="product-info-title">供货价（$）</div>
          <div class="product-info-desc">{{forme.supplyMoney || '/' | formatMoney}}</div>
        </el-col>
      </el-row>

    </div>

    <div class="product-charts">
      <div class="product-charts-date">
        <div>
          <span class="product-charts-date-mark"></span>
          <span class="font-18 product-charts-date-title">数据详情</span>
        </div>
      </div>
      <div>
        <qx style="width: 100%;height: 387px;overflow: hidden;" ref="qx" :chartName="'qx1'" :dataList='dataList'></qx>
      </div>
    </div>

    <div class="product-admin" v-if="shopData && shopData.id" v-loading='chartsloading'>
      <div class="product-charts-date">
        <div>
          <span class="product-charts-date-mark"></span>
          <span class="font-18 product-charts-date-title">店铺详情</span>
        </div>
      </div>
      <div class="product-head">
        <div class="product-head-img">
          <img style="width: 200px;height: 200px;border-radius: 10px;" :src="(shopData.cover || require('../../assets/imgs/empty.png')) | image(200,200) " alt="">
        </div>
        <div class="product-head-content font-12">
          <div>
            <div class="product-head-content-title">
              <div class="product-head-content-title-name">
                {{shopData.title}}
                <span @click="copyTex(shopData.title)" style="line-height: 31px;border:1px solid #33333365; width: 103px;height: 31px;background-color: #43434313;color: #333333;margin-right: 20px;border-radius: 5px;cursor: pointer;"> <i class="el-icon-document-copy"></i> 复制</span>
              </div>
              <div class="product-head-content-title-type" v-if="shopData.categoryList && shopData.categoryList.length > 0">
                <div class="product-head-content-title-type-tag">
                  <div v-for="item in shopData.categoryList">
                    <el-tag style="margin-top:8px;margin-right:8px">{{item}}</el-tag>
                  </div>
                </div>

              </div>
            </div>
            <div class="font-14 product-head-content-btn">
              <div @click="tzym(shopData.shopLink)" style="width: 103px;height: 31px;background-color: #a4b9f42f;color: #4975E9;border-radius: 5px;cursor: pointer;"><i class="el-icon-link"></i>店铺链接</div>
              <el-divider direction="vertical" class="shop-head-content-btn-divider"></el-divider>
              <div v-if="this.user && this.user.id" @click="toCollectShop(shopData.isCollect ? 1 : 0)" style="width: 103px;height: 31px;background-color:#008000;color: #fff;border-radius: 5px;cursor: pointer;">{{ !shopData.isCollect ? '收藏' : '取消收藏'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="font-14 product-info">
        <el-row>
          <el-col :span="6">
            <div class="product-info-title">开店时间</div>
            <div class="product-info-desc">{{shopData.shopOnlineTime || '/'}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">商品数</div>
            <div class="product-info-desc">{{shopData.items || 0 | formatMoney}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">总销量</div>
            <div class="product-info-desc">{{shopData.shopSold || 0 | formatMoney}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">粉丝数</div>
            <div class="product-info-desc">{{shopData.follower || 0 | formatMoney}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">总销售额（$）</div>
            <div class="product-info-desc">{{shopData.shopSoldAmount || 0 | formatMoney}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">评分</div>
            <div class="product-info-desc">{{shopData.score || 0}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">评论数</div>
            <div class="product-info-desc">{{shopData.comments || 0 | formatMoney}}</div>
          </el-col>
          <el-col :span="6">
            <div class="product-info-title">平均客单价（$）</div>
            <div class="product-info-desc">{{shopData.averagePrice || 0 | formatMoney}}</div>
          </el-col>
        </el-row>

      </div>
    </div>
    <!--  -->
    <!-- <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="keyword" label="相关关键词" width="180">
      </el-table-column>
      <el-table-column prop="dsr" label="交易指数" width="180">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <el-progress :percentage="scope.row.dsr/50 > 100 ?  100 : scope.row.dsr/50 " :show-text="false" define-back-color="#fff"></el-progress>
            <div>{{ scope.row.dsr | formatMoney }}</div>

          </div>
        </template>
      </el-table-column>
      <el-table-column prop="itemCount" label="商品数">
        <template slot-scope="scope">
          <div>{{ scope.row.itemCount | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="mallCount" label="店铺数量">
        <template slot-scope="scope">
          <div>{{ scope.row.mallCount | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="totalSold" label="总销量">
        <template slot-scope="scope">
          <div>{{ scope.row.totalSold | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="平均客单价">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <div>{{ scope.row.avgPrice }}</div>

          </div>
        </template>
      </el-table-column>
    </el-table> -->

    <!-- 相似图片弹窗 -->
    <same_pic :showSearch="showSearch" :pic_url='forme.imageUrl' @close="showSearch = false"></same_pic>
  </div>
</template>
      
      <script>
import { showDetail, agetSaleAmtAndSumTrend, agetRelateFlowList, getStatisticDate, collectProduct, collectShop, agetBasicInfo, getUserInfo } from "../../api/api";
import tendency from "../../components/datazx";
import { Base64 } from 'js-base64';
import qx from "../../components/qx";
import same_pic from '../../components/same_pic.vue';
export default {
  components: {
    tendency, qx, same_pic
  },
  data() {
    return {
      curPage: "1",
      pageSize: 10,
      totale: "",
      data1: "",
      radio2: "",
      forme: {},
      tableData: [

      ],
      sjsj: '',
      pllist: [
      ],
      //图表加载
      loading: false,
      chartsloading: false,
      //默认选中日期
      date_type: 2,
      //销售量数据
      xl_data: {
        num: 0,
        hb: 0,
      },
      user: {},
      //搜同款
      showSearch: false,
      dataList: [],
      shopData: {},
    };
  },
  created() {
    this.autoLogin();
  },
  methods: {
    async autoLogin() {
      this.loading = true;
      const { data: res } = await getUserInfo();
      this.loading = false;
      if(res.code == '0000') {
        const { data } = res;
        if(data) {
          const { user, userBenefits } = data;
          if(user) {
            console.log('confirmLoginByMobile', res, data);
            this.user = user;
            let is_vip = false;
            if(userBenefits && userBenefits.length > 0) {
              userBenefits.map(v => {
                if(v && v.productCode && v.productCode == 1002) {
                  is_vip = v.isValid == 0 ? false : true;
                }
              })
            }
            this.$cookie.set('is_vip', is_vip);
            this.$nextTick(() => {
              this.showDetail();
            })
          } else {
            this.toLogin();
          }
        } else {
          this.toLogin();
        }
      } else {
        this.toLogin();
      }
    },
    toLogin() {
      let jumpUrl = process.env.VUE_APP_LOGIN_URL;
      let redirectUrl = process.env.VUE_APP_MANAGE_URL + this.$route.fullPath;
      let az = Base64.encode(redirectUrl);
      window.location.href = jumpUrl + '?redirectUrl=' + az;
    },
    async toCollectShop(data) {
      let param = {};
      param['type'] = data;
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      param['shopIds'] = [this.shopData.id + '' || ''];
      const { data: res } = await collectShop(param);
      console.log('collectShop', res.data);
      this.agetBasicInfo();
    },
    findSame() {
      const url = 'https://air.1688.com/app/channel-fe/search/index.html#/result?image_list=' + this.forme.imageUrl;
      window.open(url, "_blank");
    },
    async toCollectProduct(data) {
      let param = {};
      param['type'] = data;
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      param['productIds'] = [this.$route.query.id];
      const { data: res } = await collectProduct(param);
      console.log('toCollectProduct', res.data);
      this.showDetail();
    },
    //更改销售量数据展示
    changeDate(data) {
      //   this.date_type = data;
      //   this.agetSaleAmtAndSumTrend();
      //   if(this.date_type == 1) {
      //     this.xl_data = {
      //       num: this.forme.weekSalesAmount,
      //       hb: this.forme.weekSalesAmountIncrease,
      //     }
      //   } else {
      //     this.xl_data = {
      //       num: this.forme.monthSalesAmount,
      //       hb: this.forme.monthSalesAmountIncrease,
      //     }
      //   }
    },
    //跳转链接
    tzym(e) {
      window.open(e, "_blank");
    },
    //复制商品名
    copyTex(e) {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = e //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success('复制成功')

    },
    async getStatisticDate(e) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 25;
      param["field"] = '';
      param["sort"] = '';

      param["firstCategory"] = "";

      const { data: res } = await getStatisticDate(param);
      let a = res.data

      this.sjsj = a

    },
    //获取商品详情
    async showDetail() {
      this.loading = true;
      let param = {};
      param["dateType"] = this.date_type;
      param["id"] = this.$route.query.id
      param["goodsId"] = '';
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      const { data: res } = await showDetail(param);
      this.loading = false;
      this.forme = res.data;
      if(this.forme && this.forme.shopId) {
        this.agetBasicInfo();
        this.agetSaleAmtAndSumTrend();
      }
      //   this.changeDate(this.date_type);
    },
    //获取商品销售趋势
    async agetSaleAmtAndSumTrend() {
      this.chartsloading = true;
      let param = {};
      param["dateType"] = this.date_type
      // param["id"] =this.$route.query.id;
      param["goodsId"] = this.forme.goodsId;
      const { data: res } = await agetSaleAmtAndSumTrend(param);
      //   this.agetRelateFlowList(this.date_type)
      //   this.getStatisticDate(2)
      this.dataList = res.data
      this.chartsloading = false
      //   if(this.date_type == 1) {
      //     this.pllist = [
      //       {
      //         title: '销售量指数',
      //         num: this.forme.weekSold,
      //         hb: this.forme.weekSoldIncrease
      //       },
      //       {
      //         title: '平均客单价',
      //         num: this.forme.weekAvgPrice,
      //         hb: this.forme.weekAvgPriceIncrease
      //       },
      //     ]
      //   } else if(this.date_type == 2) {
      //     this.pllist = [
      //       {
      //         title: '销售量指数',
      //         num: this.forme.monthSold,
      //         hb: this.forme.monthSoldIncrease
      //       },
      //       {
      //         title: '平均客单价',
      //         num: this.forme.monthAvgPrice,
      //         hb: this.forme.monthAvgPriceIncrease
      //       },
      //     ]
      //   }

    },
    //获取店铺详情
    async agetBasicInfo() {
        this.loading = true;
      let param = {};
      param["dateType"] = this.date_type;
      param["thirdShopId"] = this.forme.shopId
      param['userId'] = this.user && this.user.id ? this.user.id : 0;

      const { data: res } = await agetBasicInfo(param);
      this.loading = false;
      this.shopData = res.data;
    },
    //获取底部表格数据
    async agetRelateFlowList() {
      let param = {};
      param["dateType"] = this.date_type
      param["id"] = this.$route.query.id
      param["goodsId"] = '';
      const { data: res } = await agetRelateFlowList(param);
      this.tableData = res.data
      // this.forme = res.data;

    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
      },
      immediate: true,
    },
  },

};
  </script>
      
<style lang="less" scoped>
.diadin {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 12px;
  &_divider {
    height: 20px !important;
    margin: 0 24px !important;
  }
}
.product {
  width: calc(100% - 700px);
  min-width: 1000px;
  &-head {
    display: flex;
    padding: 20px;
    align-items: flex-start;
    &-img {
      width: 200px;
      height: 200px;
      margin-right: 72px;
      border-radius: 10px;
    }
    &-content {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      &-title {
        color: #333;
        margin-bottom: 14px;
        &-name {
          color: #333;
          margin-bottom: 14px;
          &-en {
            color: #999;
          }
        }
        &-type {
          width: 100%;
          &-tag {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            max-height: 120px;
            overflow: hidden;
          }
        }
      }
      &-btn {
        display: flex;
        text-align: center;
        line-height: 31px;
        align-items: center;
      }
    }
  }
  &-charts {
    background-color: #fff;
    margin-top: 20px;
    padding: 17px;
    &-date {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &-mark {
        display: inline-block;
        width: 4px;
        background-color: #4975e9;
        height: 15px;
        margin-right: 5px;
      }
      &-title {
        font-weight: bold;
        line-height: 15px;
      }
      &-choose {
        cursor: pointer;
        &-select {
          color: #409eff;
        }
      }
    }
  }
  &-info {
    width: 100%;
    padding: 20px;
    color: #333333;
    line-height: 31px;
    &-title {
      background: #f2f9fc;
      padding: 10px 20px;
      border: 1px solid #e4eef6;
      word-break: break-all;
      word-wrap: break-word;
    }
    &-desc {
      background: #fff;
      padding: 10px 20px;
      border: 1px solid #e4eef6;
      word-break: break-all;
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-admin {
    padding: 17px;
  }
}
.border {
  border-bottom: 1px solid #eeeeee;
}
.minisize {
  font-style: 14px;
  span {
    margin: 0 8px;
    cursor: pointer;
  }
  .stotusbox {
    color: #d4d4d4;
    // font-size: 15px;
  }
}
/deep/.el-select {
  width: 100%;
}
.actoin {
  color: #409eff !important;
}
/deep/.el-form-item__content {
  width: 82%;
}
/deep/.el-input-group__append {
  width: 40%;
}
/deep/.el-input__inner {
  background-color: #f5f7fa !important;
  height: 35px !important;
  width: 100%;
}
/deep/.el-input__icon {
  line-height: 35px !important;
}
.titlable {
  width: 130px;
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
}
</style>